import { render, staticRenderFns } from "./taiwan-user-component.vue?vue&type=template&id=411dd7cd&scoped=true&"
import script from "./taiwan-user-component.vue?vue&type=script&lang=js&"
export * from "./taiwan-user-component.vue?vue&type=script&lang=js&"
import style0 from "./taiwan-user-component.vue?vue&type=style&index=0&id=411dd7cd&prod&scoped=true&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "411dd7cd",
  null
  
)

export default component.exports