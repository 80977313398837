<template>
  <div>
    <!--    <div flex-row-flex-start-flex-start class="tag-spacing">-->
    <!--      <span class="component-label">户籍所在省（市）：</span>-->
    <!--      <div flex-row-flex-start-center>-->
    <!--        {{ nativeComputed }}-->
    <!--      </div>-->
    <!--    </div>-->

    <div flex-row-flex-start-flex-start class="tag-spacing">
      <span class="component-label">证件号码：</span>
      <div flex-row-flex-start-center>
        {{ answerJsonValue.cardNumber }}
      </div>
    </div>

    <div flex-row-flex-start-flex-start class="tag-spacing">
      <span class="component-label">出生日期：</span>
      <div flex-row-flex-start-center>
        {{ answerJsonValue.birthday }}
      </div>
    </div>

    <div flex-row-flex-start-flex-start class="tag-spacing" v-if="answerJsonValue.cardFrontImg || answerJsonValue.cardReverseImg">
      <span class="component-label">台胞证证件照：</span>
      <div flex-row-flex-start-center>
        <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(answerJsonValue.cardFrontImg)" alt="" :preview-src-list="[normalizeImageUrl(answerJsonValue.cardFrontImg)]"></el-image>
        <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(answerJsonValue.cardReverseImg)" alt="" :preview-src-list="[normalizeImageUrl(answerJsonValue.cardReverseImg)]"></el-image>
      </div>
    </div>

    <div flex-row-flex-start-flex-start class="tag-spacing" v-if="answerJsonValue.residentialFrontImg || answerJsonValue.residentialReverseImg">
      <span class="component-label">台胞居民居住证：</span>
      <div flex-row-flex-start-center>
        <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(answerJsonValue.residentialFrontImg)" alt="" :preview-src-list="[normalizeImageUrl(answerJsonValue.residentialFrontImg)]"></el-image>
        <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(answerJsonValue.residentialReverseImg)" alt="" :preview-src-list="[normalizeImageUrl(answerJsonValue.residentialReverseImg)]"></el-image>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

export default {
  inject: {
    ZtCountryAdvance: { from: 'ZtCountryAdvance' },
  },
  // extends   : {},
  mixins    : [],
  components: {},
  name      : 'TaiwanUserComponent',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {}
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    nativeComputed() {
      return this.ZtCountryAdvance.nativeComputed
    },
    answerJsonValue() {
      return this.ZtCountryAdvance.answerJsonValue
    },
  },
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "../component.scss";
</style>
